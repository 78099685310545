var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h100",class:{ 'sq-wrapper': _vm.$b.d, 'product-card-sq-wrapper-mobile': _vm.$b.mt }},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('v-card',{staticClass:"h100 d-flex flex-column overflow-hidden justify-space-between base product-card",class:[_vm.$bem('sq'), { cardBorder: _vm.cardBorder }],attrs:{"hover":!_vm.outOfStock,"ripple":false},on:{"click":_vm.cardClick}},[_c('div',{class:[_vm.$bem('__image-cont mx-2'), { 'no-stock': _vm.outOfStock }]},[_c('hook',{attrs:{"zone":"image-sq"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"cover":"","src":_vm.mainImage,"width":"100%","aspect-ratio":"1.5","imgClass":_vm.$b.d
								? 'sq__image--smallWidth'
								: _vm.$b.t
								? 'sq__image--smallWidth-tablet'
								: 'sq__image--smallWidth-mobile'}}),(_vm.secondImage && _vm.hover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), _vm.hover ? _vm.$bem('__second-image--show') : ''],attrs:{"cover":"","src":_vm.secondImage,"width":"100%","aspect-ratio":"1.5","imgClass":_vm.$b.d
									? 'sq__image--smallWidth'
									: _vm.$b.t
									? 'sq__image--smallWidth-tablet'
									: 'sq__image--smallWidth-mobile'}})],1):_vm._e()],1),_c('CollectionEventIcon',{class:[
						_vm.$bem('__event-icon'),
						{
							'sq__event-icon--top': _vm.eventIconTop,
							'sq__event-icon--right': _vm.eventIconRight,
						} ],attrs:{"product":_vm.product,"imgWidth":_vm.$b.m ? '20px' : '25px'}})],1),_c('div',{class:[_vm.$bem('__data-cont'), 'd-flex flex-column mx-2 h100 pb-2', { 'no-stock': _vm.outOfStock }]},[_c('ProductCardPrice',{style:({ order: _vm.elementOrder.price }),attrs:{"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":_vm.priceContainerClass,"prevPriceClass":_vm.prevPriceClass,"priceClass":_vm.priceClass,"discountClass":_vm.discountClass,"prevPriceGroup":_vm.prevPriceGroup,"elementOrder":_vm.priceElementsOrder,"sq":""}}),_c('router-link',{staticClass:"card-anchor header",style:({ order: _vm.elementOrder.name }),attrs:{"to":_vm.productRoute}},[_c('hook',{attrs:{"zone":"name-sq"}},[_c('div',{class:[
								_vm.$bem('__name'),
								'line-clamp-1 header font-1',
								_vm.alignment == 'left'
									? 'text-start'
									: _vm.alignment == 'center'
									? 'text-center'
									: 'text-end' ]},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])])],1),(_vm.brand)?_c('hook',{style:({ order: _vm.elementOrder.brand }),attrs:{"zone":"brand-sq"}},[(_vm.brand)?_c('div',{class:[
							_vm.$bem('__brand', 'font-0 line-clamp-1'),
							_vm.alignment == 'left'
								? 'text-start'
								: _vm.alignment == 'center'
								? 'text-center'
								: 'text-end' ]},[_vm._v(" "+_vm._s(_vm.brand)+" ")]):_vm._e()]):_vm._e(),(_vm.hasWishlist)?_c('hook',{attrs:{"zone":"wishlist-toggler-sq"}},[_c('ProductWishlistToggler',{class:[_vm.$bem('__wishlist-toggler'), { 'no-stock': _vm.outOfStock }],staticStyle:{"z-index":"1"},attrs:{"productId":_vm.product.id,"height":25,"width":25,"iconSize":"18px"}})],1):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }