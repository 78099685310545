var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.verticalCard)?_c('div',{class:[
		_vm.verticalWrapperClasses,
		{
			'product-card--align-right': _vm.alignment == 'right',
			'product-card--align-center': _vm.alignment == 'center',
		} ]},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('v-card',{class:[
				_vm.$bem('product-card-design7-vertical'),
				_vm.alignment == 'left' ? 'text-start' : _vm.alignment == 'center' ? 'text-center' : 'text-end',
				{
					'card-border': _vm.cardBorder,
				},
				_vm.verticalCardClasses ],style:({ height: _vm.hasHoverAction && _vm.hover ? _vm.hoverForBtn : '100%' }),attrs:{"hover":!_vm.outOfStock,"ripple":false,"elevation":"2"},on:{"click":_vm.cardClick}},[_c('div',{class:[_vm.$bem('__image-cont'), { 'no-stock': _vm.outOfStock }]},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"src":_vm.mainImage,"width":"100%","aspect-ratio":"1"}}),(_vm.secondImage && _vm.hover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), _vm.hover ? _vm.$bem('__second-image--show') : ''],attrs:{"src":_vm.secondImage,"width":"100%"}})],1):_vm._e(),(_vm.hasLimitedTimeOffer)?_c('LimitedTimeOffer',{class:_vm.$bem('__offer'),attrs:{"product":_vm.product}}):_vm._e(),_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product}})],1),_c('div',{class:[
					_vm.$bem('__data-cont'),
					'd-flex flex-column mx-3',
					{ 'no-stock': _vm.outOfStock, h100: _vm.hasVisibleAction } ],staticStyle:{"gap":"6px"}},[_c('ProductCardPrice',{class:_vm.$bem('__price-container'),attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":[
						'd-flex align-center line-clamp-1 pa-1',
						{
							'justify-center': _vm.alignment == 'center',
							'justify-end w100': _vm.alignment == 'right',
							'mt-2': _vm.discountPct == 0,
						} ],"prevPriceClass":[_vm.$bem('__prev-price'), 'grey--text font-2 ml-2'],"priceClass":[_vm.$bem('__price'), 'price font-6 line-clamp-1'],"discountClass":[
						_vm.$bem('__discount'),
						'd-inline-block discount font-2 mx-1 my-2',
						{
							'alignment-center': _vm.alignment == 'center',
							'alignment-right': _vm.alignment == 'right',
						} ]}}),_c('router-link',{staticClass:"card-anchor header",attrs:{"to":_vm.productRoute}},[_c('div',{class:[_vm.$bem('__name'), 'line-clamp-2 font-2 px-1 header']},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])]),_c('ProductCard-VariantSelector'),_c('ProductCard-TextTag'),_c('ProductCard-FinancingTag'),_c('ProductCard-Tags',{staticClass:"font-0"}),_c('ProductCard-AttrsTags'),_c('ProductCardRating',{attrs:{"product":_vm.product}}),_c('v-spacer'),_c('ProductCard-Actions')],1)])],1)],1):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onLinkBlurMobile),expression:"onLinkBlurMobile"}],staticClass:"w100"},[_c('v-card',{class:[
			_vm.$bem('product-card-design7-horizontal'),
			_vm.alignment == 'left' ? 'text-start' : _vm.alignment == 'center' ? 'text-center' : 'text-end',
			{ 'card-border': _vm.cardBorder },
			_vm.horizontalCardClasses ],attrs:{"ripple":false},on:{"click":_vm.cardClick}},[_c('div',{class:[
				_vm.$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
				{ 'no-stock': _vm.outOfStock } ]},[_c('Media',{class:_vm.$bem('__main-image', 'px-1'),attrs:{"src":_vm.mainImage,"width":"100%","imgClass":"product-card-design7-horizontal__image--smallWidth"}}),(_vm.hasLimitedTimeOffer)?_c('LimitedTimeOffer',{staticClass:"mx-2 rounded line-clamp-1",class:_vm.$bem('__offer'),staticStyle:{"width":"inherit","padding":"0 4px !important"},attrs:{"product":_vm.product,"textAlign":"start"}}):_vm._e()],1),_c('div',{staticClass:"col col-7 py-0 pl-1 d-flex flex-column",class:[_vm.$bem('__data-cont'), { 'no-stock': _vm.outOfStock }],staticStyle:{"gap":"6px"}},[_c('ProductCardPrice',{class:_vm.$bem('__price-container'),attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":'d-flex align-center line-clamp-1 pb-1',"prevPriceClass":['grey--text font-1', _vm.$bem('__prev-price')],"priceClass":[_vm.$bem('__price'), 'price font-6 line-clamp-1 mr-1'],"discountClass":[_vm.$bem('__discount'), 'd-inline-block discount font-2', { 'mt-3': _vm.hasAction }],"horizontalCard":""}}),_c('ProductCard-TextTag'),_c('ProductCard-FinancingTag'),_c('ProductCard-Tags',{staticClass:"font-0"}),_c('ProductCard-AttrsTags',{attrs:{"vertical-card":false}}),_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var navigate = ref.navigate;
			var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){return _vm.onLinkClickMobile(navigate, $event)}}},[_c('div',{class:[_vm.$bem('__name'), 'line-clamp-2 font-3 header']},[_vm._v(_vm._s(_vm.product.name))])])]}}])}),_c('ProductCard-VariantSelector'),_c('ProductCardRating',{attrs:{"product":_vm.product}}),_c('v-spacer'),_c('ProductCard-Actions'),_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product,"imgWidth":"25px"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }