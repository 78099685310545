<script>
import hooksMixin from '@/v-shop/mixins/hooks-mixin'
import { get } from 'vuex-pathify'

export default {
	inject: ['manager'],
	data() {
		return {
			tooltipTimeout: null,
			showTooltip: false,
			loading: false,
			qty: 0,
		}
	},
	computed: {
		order: get('cart/order'),
		orderItem() {
			return this.order?.items.find((item) => item.refId == this.manager.selectedVariant.id)
		},
		orderItemQty() {
			return this.orderItem?.qty || 0
		},
		showQtyInput() {
			return this.manager.canBeAddedToCart && this.manager.actionType == 'qty' && this.qty > 0
		},
		maxStockQty() {
			let { maxBuyableQty, infiniteQty, qty } = this.manager.selectedVariant.stock
			if (maxBuyableQty >= 1) {
				if (infiniteQty) return maxBuyableQty
				else return Math.min(qty, maxBuyableQty)
			} else {
				if (infiniteQty) return 0
				else return qty
			}
		},
		unitMetricWord() {
			if (!this.orderItem) return
			let x = this.orderItem.unitMetric.split('/')
			return this.orderItemQty == 1 ? x.shift() : x.join('')
		},
		actionWrapperClass() {
			if (this.manager.verticalCard)
				return {
					btnHoverVertical: this.manager.hasHoverAction,
					'btnHoverVertical--show': this.manager.hasHoverAction && this.manager.hover,
				}
			else
				return {
					btnHoverHorizontal: this.manager.hasHoverAction,
					'btnHoverHorizontal--show': this.manager.hasHoverAction && this.manager.hover,
				}
		},
	},
	watch: {
		qty(val) {
			if (val == this.orderItemQty) return
			if (val <= 0) this.removeFromCart()
			else this.updateCart(val)
		},
		order() {
			this.qty = this.orderItemQty
		},
		'manager.selectedVariant'() {
			this.qty = this.orderItemQty
			this.showTooltip = false
		},
	},
	methods: {
		async addToCart() {
			if (!this.manager.canBeAddedToCart) return
			await this.$shop.addToCart({
				qty: 1,
				sum: true,
				productId: this.manager.product.id,
				variantId: this.manager.selectedVariant.id,
				showCart: false,
				loading: (v) => (this.loading = v),
			})
			this.showTooltipMsg()
		},
		async removeFromCart() {
			if (!this.orderItem) return
			await this.$shop.deleteCartItem({
				cartItemId: this.orderItem.cartItemId,
				loading: (v) => (this.loading = v),
			})
			this.showTooltipMsg()
		},
		async updateCart(qty) {
			if (!this.orderItem) return
			await this.$shop.updateCartItem({
				cartItemId: this.orderItem.cartItemId,
				qty,
				loading: (v) => (this.loading = v),
			})
			this.showTooltipMsg()
		},
		showTooltipMsg() {
			this.$nextTick(() => {
				this.showTooltip = true
				if (this.tooltipTimeout) clearTimeout(this.tooltipTimeout)
				this.tooltipTimeout = setTimeout(() => {
					this.showTooltip = false
				}, 2000)
			})
		},
	},
	mounted() {
		this.qty = this.orderItemQty
	},
}
</script>

<template>
	<div v-if="manager.hasAction" :class="{ z1: manager.canBeAddedToCart }">
		<div
			class="px-3 my-3"
			:class="{
				'btn-hover': manager.hasHoverAction,
				'--vertical': manager.verticalCard && !manager.featured,
				'--show': this.manager.hasHoverAction && this.manager.hover,
			}"
		>
			<v-fade-transition>
				<div class="product-card-tooltip-wrapper" v-if="showTooltip">
					<div
						class="product-card-tooltip px-3 py-1 rounded text-center"
						:class="[orderItemQty ? '--green' : '--red', $b.mt && 'font-0 pa-1 --mobile']"
					>
						<span v-if="orderItemQty">
							Agregaste
							<b>{{ orderItemQty }} {{ unitMetricWord }}</b>
							<br v-if="$b.mt" />
							al carrito
						</span>
						<span v-else>Removido del carrito</span>
					</div>
				</div>
			</v-fade-transition>

			<QtyInput
				v-if="showQtyInput"
				v-model="qty"
				:class="['product-card__qty-input', { '--mobile': $b.m, '--loading': loading }]"
				:disabled="manager.outOfStock"
				:max-qty="maxStockQty"
				:min-qty="manager.selectedVariant.stock.minBuyableQty"
				:unit-metric="manager.product.unitMetric"
				:pack-metric="manager.product.packMetric"
				:step="manager.selectedVariant.metricFactor"
				:loading="loading"
				allow-zero
			/>
			<v-btn
				v-else
				class="cta w100 rounded-md"
				:disabled="manager.outOfStock"
				:loading="loading"
				@click="addToCart"
			>
				{{ manager.btnText }}
			</v-btn>
		</div>
	</div>
</template>

<style scoped lang="scss">
.product-card-tooltip-wrapper {
	position: relative;
}
.product-card-tooltip {
	white-space: nowrap;
	position: absolute;
	bottom: -100%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
	color: white;

	&::before {
		content: '';
		position: absolute;
		bottom: -40%;
		left: 50%;
		transform: translateX(-50%);
		border-width: 15px 15px 0;
		border-style: solid;
	}

	&.--mobile::before {
		bottom: -15px;
	}

	&.--green {
		background-color: #4caf50;
		&::before {
			border-color: #4caf50 transparent transparent;
		}
	}
	&.--red {
		background-color: #f44336;
		&::before {
			border-color: #f44336 transparent transparent;
		}
	}
}

.product-card__qty-input {
	max-height: 36px;
	&.--loading {
		opacity: 0.5;
		pointer-events: none;
	}

	::v-deep .qty-btn {
		&__wrapper {
			width: 100%;
		}
		&__container {
			justify-content: space-around;
			width: 100%;
		}
		&__tf {
			width: unset !important;
			max-width: unset !important;
		}
	}

	&.--mobile ::v-deep {
		.qty-btn {
			&__plus,
			&__minus {
				width: 25px;
			}
			&__tf {
				width: unset !important;
				& .v-input__slot {
					padding: 0;
					& input {
						font-size: 0.8rem;
					}
				}
			}
		}
	}
}

.btn-hover {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.4s;

	&.--vertical {
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translateY(15px);
		transition: opacity 0.4s, transform 0.2s ease-out;
	}

	&.--show {
		opacity: 1;
		transform: translateY(0px);
		visibility: visible;
		width: 100%;
	}
}
</style>

