<script>
export default {
	inject: ['manager'],
	computed: {
		variantsForSelector() {
			return [...this.manager.product.variants].sort(
				(a, b) =>
					b.forDisplay - a.forDisplay || !this.manager.isOutOfStock(b) - !this.manager.isOutOfStock(a)
			)
		},
		visibleVariantsForSelector() {
			return this.variantsForSelector.length > 4
				? this.variantsForSelector.slice(0, 3)
				: this.variantsForSelector
		},
		showPlus() {
			return this.variantsForSelector.length > 4
		},
	},
}
</script>
<template>
	<div
		v-if="manager.hasVariantSelector && visibleVariantsForSelector.length"
		class="product-attrs font-0 font-md-1 my-2 py-2"
		:class="$b.d && '--desktop'"
		@touchstart="$event.stopPropagation()"
	>
		<div
			v-for="variant of visibleVariantsForSelector"
			:key="variant.id"
			@click="manager.selectedVariantId = variant.id"
			class="__chip"
			:class="manager.selectedVariant.id == variant.id ? '--selected' : ''"
			:style="{ opacity: manager.isOutOfStock(variant) ? 0.5 : 1 }"
		>
			{{ variant.attrs[0].v }}
		</div>
		<SafeLink v-if="showPlus" :to="manager.productRoute">
			<div class="__chip px-3">+</div>
		</SafeLink>
	</div>
</template>

<style scoped lang="scss">
.product-attrs {
	display: flex;
	z-index: 99;
	gap: 6px;
	overflow: auto;

	.__chip {
		color: var(--basetext);
		padding: 6px;
		border: 1px solid var(--link) !important;
		text-align: center;
		border-radius: 4px;
		cursor: pointer;
		white-space: nowrap;

		&.--selected {
			color: var(--errortext);
			background-color: var(--error);
			border-color: var(--linktext);
		}
	}

	&.--desktop {
		overflow: hidden;

		.__chip {
			text-overflow: ellipsis;
			min-width: 45px;
			max-height: 54px;
			white-space: normal;

			// Line clamp 2
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			word-break: normal;
		}
	}
}
</style>

