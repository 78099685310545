<script>
export default {
	inject: ['manager'],
}
</script>

<template>
	<div
		v-if="manager.textTags && manager.textTags.length"
		class="d-flex align-center w100"
		:class="{
			'justify-center': manager.alignment == 'center',
			'justify-end': manager.alignment == 'right',
		}"
	>
		<div
			v-for="({ text }, n) in manager.textTags"
			:key="n"
			class="product-card__text-tag font-0 line-clamp-1 px-1 error rounded"
		>
			{{ text }}
		</div>
	</div>
</template>

