<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		:class="[
			verticalWrapperClasses,
			{
				'product-card--align-right': alignment == 'right',
				'product-card--align-center': alignment == 'center',
			},
		]"
		v-if="verticalCard"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock"
				@click="cardClick"
				:ripple="false"
				elevation="2"
				:class="[
					$bem('product-card-design3-vertical'),
					alignment == 'left' ? 'text-start' : alignment == 'center' ? 'text-center' : 'text-end',
					{ 'card-border': cardBorder },
					verticalCardClasses,
				]"
				:style="{ height: hasHoverAction && hover ? hoverForBtn : '100%' }"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }]">
					<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
					<div v-if="secondImage && hover && imgHover" :class="$bem('__second-image-cont')">
						<Media
							:src="secondImage"
							width="100%"
							:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
						/>
					</div>
					<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-3',
						{ 'no-stock': outOfStock, h100: hasVisibleAction },
					]"
					style="gap: 6px"
				>
					<router-link class="card-anchor header" :to="productRoute">
						<div :class="[$bem('__name'), 'line-clamp-2 font-2 px-1 header mt-2']">
							{{ product.name }}
						</div>
					</router-link>

					<ProductCard-VariantSelector />

					<ProductCard-TextTag />

					<ProductCardPrice
						:class="$bem('__price-container')"
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							'd-flex align-center line-clamp-1 pa-1',
							{ 'justify-center': alignment == 'center', 'justify-end w100': alignment == 'right' },
						]"
						:prevPriceClass="[$bem('__prev-price'), 'grey--text font-2 ml-2']"
						:priceClass="[$bem('__price'), 'price font-6 line-clamp-1']"
						:discountClass="[
							$bem('__discount'),
							'd-inline-block discount font-2 mx-1',
							{
								'alignment-center': alignment == 'center',
								'alignment-right': alignment == 'right',
							},
						]"
					/>

					<ProductCard-FinancingTag />

					<ProductCard-Tags class="font-0" />

					<ProductCardRating :product="product" />

					<ProductCard-AttrsTags class="my-1" />

					<v-spacer />

					<ProductCard-Actions />
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE SHOP -->
	<div class="w100" v-else v-click-outside="onLinkBlurMobile">
		<v-card
			@click="cardClick"
			:ripple="false"
			:class="[
				$bem('product-card-design3-horizontal'),
				{ 'card-border': cardBorder },
				horizontalCardClasses,
			]"
		>
			<div
				:class="[
					$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
					{ 'no-stock': outOfStock },
				]"
			>
				<Media
					:class="$bem('__main-image', 'px-1')"
					:src="mainImage"
					width="100%"
					imgClass="product-card-design3-horizontal__image--smallWidth"
				/>

				<LimitedTimeOffer
					:product="product"
					textAlign="start"
					class="mx-2 rounded line-clamp-1"
					style="width: inherit; padding: 0 4px !important"
					v-if="hasLimitedTimeOffer"
					:class="$bem('__offer')"
				/>
			</div>

			<div
				class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
				:class="[$bem('__data-cont'), { 'no-stock': outOfStock }]"
				style="gap: 6px"
			>
				<router-link :to="productRoute" custom v-slot="{ navigate, href }">
					<a class="card-anchor header" @click="onLinkClickMobile(navigate, $event)" :href="href">
						<div :class="[$bem('__name'), 'line-clamp-2 font-3 header', { 'mt-3': hasAction }]">
							{{ product.name }}
						</div>
					</a>
				</router-link>

				<ProductCard-VariantSelector />

				<ProductCard-TextTag />

				<ProductCard-Tags class="font-0" />

				<ProductCardPrice
					:class="$bem('__price-container')"
					:product="product"
					:currency="currency"
					:priceAmount="price"
					:prevPriceAmount="prevPrice"
					:discountPct="discountPct"
					:containerClass="'d-flex align-center line-clamp-1 pb-1'"
					:prevPriceClass="['grey--text font-1', $bem('__prev-price')]"
					:priceClass="[$bem('__price'), 'price font-6 line-clamp-1 mr-1']"
					:discountClass="[$bem('__discount'), 'd-inline-block discount font-2']"
					horizontalCard
				/>

				<ProductCard-FinancingTag />

				<ProductCardRating :product="product" />

				<v-spacer />

				<ProductCard-Actions />

				<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
			</div>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE SHOP -->
</template>

<style lang="scss" scoped>
.card-border {
	border: 1px solid #b2b2b2;
}

.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design3-vertical-wrapper {
	// min-height: 380px;
	width: 250px;

	&-forBtn {
		padding-bottom: 60px;
	}
}

.product-card-design3-vertical {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		top: 10px;
	}

	&__event-icon {
		position: absolute;
		top: 3px;
		left: 8px;
		z-index: 1;
		width: 40px;
	}

	&__name {
		display: -webkit-box;
	}

	&__price-container {
		display: flex;
		align-items: flex-end;
	}
}

::v-deep .product-card-design3-vertical {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.alignment-right {
		float: right;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 3px;
		border-radius: 4px;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design3-horizontal {
	// min-height: 200px;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__offer {
		position: absolute;
		top: 10px;
	}

	&__event-icon {
		position: absolute;
		top: 4px;
		right: 6px;
		z-index: 1;
		width: 25px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}
}

::v-deep div.product-card-design3-horizontal {
	&__image--smallWidth {
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}

	&__prev-price {
		display: -webkit-box;
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}
}
</style>

