var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.manager.tags && _vm.manager.tags.length)?_c('div',{class:[
		'product-card__tags d-flex flex-column',
		{
			'product-card__tags--desktop': !_vm.$b.m,
			'align-baseline': _vm.manager.alignment == 'left',
			'align-center': _vm.manager.alignment == 'center',
			'align-end': _vm.manager.alignment == 'right',
		} ]},_vm._l((_vm.manager.tags.slice(0, 3)),function(ref,n){
	var html = ref.html;
	var style = ref.style;
return _c('div',{key:n,staticClass:"product-card__tag rounded line-clamp-2 px-1",class:_vm.tagClass,style:(style),domProps:{"innerHTML":_vm._s(html)}})}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }