<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],
	props: {
		position: String,
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		v-if="verticalCard"
		:class="[
			'pb-2 product-card-design8-with-timer-vertical-wrapper',
			{
				h100: !timerProduct,
				'product-card-design8-with-timer-vertical-wrapper-forBtn': hasHoverAction,
				'product-card--align-right': alignment == 'right',
				'product-card--align-center': alignment == 'center',
			},
		]"
		v-click-outside="!$b.d && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock"
				flat
				@click="cardClick"
				:ripple="false"
				:class="[
					$bem('product-card-design8-with-timer-vertical'),
					alignment == 'left' ? 'text-start' : alignment == 'center' ? 'text-center' : 'text-end',
					'd-flex flex-column base rounded-md overflow-hidden product-card',
					{
						'card-border': cardBorder,
						h100: timerProduct && limitedTimeOffer,
					},
				]"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }]">
					<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
					<div v-if="secondImage && hover && imgHover" :class="$bem('__second-image-cont')">
						<Media
							:src="secondImage"
							width="100%"
							:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
						/>
					</div>

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-3',
						{ 'no-stock': outOfStock, 'pb-4': !timerProduct, h100: hasAction },
					]"
					style="gap: 8px"
				>
					<div
						v-if="brand"
						:class="[$bem('__brand'), 'line-clamp-1 px-1 mt-3 ', timerProduct ? 'font-0' : 'font-1']"
					>
						{{ brand }}
					</div>

					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="!$b.d ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<div
								:class="[
									$bem('__name'),
									timerProduct ? 'font-2' : 'font-3',
									'line-clamp-2 px-1 header',
									{ 'mt-3': !brand },
								]"
							>
								{{ product.name }}
							</div>
						</a>
					</router-link>

					<ProductCard-VariantSelector />

					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							'd-flex align-center line-clamp-1 px-1',
							{
								'justify-center': alignment == 'center',
								'justify-end': alignment == 'right',
							},
						]"
						:prevPriceClass="[
							$bem('__prev-price'),
							'grey--text ml-2',
							timerProduct ? 'font-1' : 'font-2',
						]"
						:priceClass="[$bem('__price'), timerProduct ? 'font-6' : 'font-7', 'price line-clamp-1']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
					/>

					<ProductCard-FinancingTag :class="timerProduct ? 'font-0' : 'font-1'" />

					<ProductCard-TextTag :class="timerProduct ? 'font-0' : 'font-1'" />

					<ProductCard-Tags :class="timerProduct ? 'font-0' : 'font-1'" />

					<ProductCardRating :product="product" />

					<ProductCard-AttrsTags />

					<v-spacer />

					<ProductCard-Actions />

					<div
						:class="[$bem('__offerTimerProduct'), 'base pt-1 mt-3']"
						v-if="timerProduct && limitedTimeOffer"
					>
						<LimitedTimeOfferCountdown
							:limitedTimeOffer="limitedTimeOffer"
							timerCard
							:class="{ 'mt-3': !hasAction }"
						/>
						<div class="mt-2 pb-2 text-center">
							<p
								:class="[
									$bem('__timer-date'),
									'text-uppercase line-clamp-3 mb-0 py-1 px-2 rounded font-0',
								]"
							>
								{{ limitedTimeOfferDate }}
							</p>
						</div>
					</div>

					<ProductWishlistToggler
						:productId="product.id"
						:class="[$bem('__wishlist-toggler'), { 'no-stock': outOfStock }]"
						style="z-index: 1"
					/>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE -->
	<div
		v-else
		:class="{ 'product-card-design8-with-timer-horizontal-wrapper-forBtn': hasHoverAction }"
		v-click-outside="onLinkBlurMobile"
	>
		<v-card
			flat
			@click="cardClick"
			:ripple="false"
			:class="[
				$bem('product-card-design8-with-timer-horizontal'),
				alignment == 'left' ? 'text-start' : alignment == 'center' ? 'text-center' : 'text-end',
				{
					'product-card-design8-with-timer-horizontal__noTimer': !timerProduct && $b.t,
					'card-border': cardBorder,
				},
			]"
			class="d-flex overflow-hidden base product-card pt-2 flex-column"
		>
			<div class="d-flex overflow-hidden py-2">
				<div
					:class="[
						$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
						{ 'no-stock': outOfStock },
					]"
				>
					<Media
						:class="$bem('__main-image', 'px-1')"
						:src="mainImage"
						:width="$b.m ? '100%' : '200px'"
						:maxHeight="$b.t ? '200px' : ''"
						imgClass="product-card-design8-with-timer-horizontal__image--smallWidth"
					/>

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
				</div>

				<div
					class="col col-7 py-0 d-flex flex-column text-start"
					:class="[
						$bem('__data-cont'),
						{ 'no-stock': outOfStock, 'my-5': !hasAction && !timerProduct },
						$b.t ? 'pl-3' : 'pl-1',
					]"
					style="gap: 6px"
				>
					<div
						v-if="brand"
						:class="[$bem('__brand'), 'line-clamp-1', timerProduct ? 'font-0' : 'font-1']"
					>
						{{ brand }}
					</div>

					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a class="card-anchor header" @click="onLinkClickMobile(navigate, $event)" :href="href">
							<div :class="[$bem('__name'), 'line-clamp-2 header', $b.m ? 'font-3' : 'font-4']">
								{{ product.name }}
							</div>
						</a>
					</router-link>

					<ProductCard-VariantSelector />

					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						containerClass="d-flex align-center line-clamp-1"
						:prevPriceClass="['grey--text ml-2', $bem('__prev-price'), $b.m ? 'font-1' : 'font-2']"
						:priceClass="[$bem('__price'), 'price line-clamp-1', $b.m ? 'font-5' : 'font-6']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
						horizontalCard
					/>

					<ProductCard-FinancingTag />

					<ProductCard-TextTag />

					<ProductCard-Tags />
					<ProductCard-AttrsTags />
				</div>
			</div>

			<ProductCardRating :product="product" />

			<v-spacer />

			<ProductCard-Actions />

			<div
				:class="[$bem('__offerTimerProduct'), 'base pt-1', { 'mt-4': $b.t, 'no-stock': outOfStock }]"
				v-if="timerProduct && limitedTimeOffer"
			>
				<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard class="mt-2" />
				<div class="mt-2 pb-2 text-center">
					<p
						class="text-uppercase line-clamp-3 mb-0 pt-1 pb-2 px-2 rounded font-0"
						:class="$bem('__timer-date')"
					>
						{{ limitedTimeOfferDate }}
					</p>
				</div>
			</div>

			<ProductWishlistToggler
				:productId="product.id"
				:class="[$bem('__wishlist-toggler'), { 'no-stock': outOfStock }]"
				style="z-index: 1"
				:height="30"
				:width="30"
			/>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE -->
</template>

<style lang="scss" scoped>
.card-border {
	border: 1px solid #b2b2b2;
}

.no-stock {
	opacity: 0.4;
}

.product-card {
	&__tags--absolute-top {
		left: 15px;
	}
	&--align-center {
		.product-card__tags {
			display: flex;
			justify-content: center;
		}
		.product-card__tags--absolute-top {
			left: 40px;
			right: 40px;
			::v-deep .product-card__tag {
				display: flex;
				justify-content: center;
			}
		}
	}
	&--align-right {
		.product-card__tags {
			display: flex;
			justify-content: flex-end;
		}
		.product-card__tags--absolute-top {
			display: flex;
			justify-content: flex-end;
			right: 45px;
		}
	}
}

// VERTICAL CARD CLASSES
.product-card-design8-with-timer-vertical {
	position: relative;
	cursor: pointer;
	justify-content: space-between;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		bottom: -15px;
		left: 8px;
		z-index: 1;
		width: 40px;
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}

	&__positionRight {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&__positionLeft {
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

::v-deep .product-card-design8-with-timer-vertical {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.alignment-right {
		float: right;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design8-with-timer-horizontal {
	&__image-cont {
		position: relative;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__event-icon {
		position: absolute;
		bottom: 0;
		left: 6px;
		z-index: 1;
	}

	&__name,
	&__timer-date,
	&__brand {
		display: -webkit-box;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 7px;
		right: 6px;
		z-index: 1;
	}
}

::v-deep .product-card-design8-with-timer-horizontal {
	&__price,
	&__prev-price {
		display: -webkit-box;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}
}
</style>

