<script>
import groupBy from 'lodash/groupBy'

export default {
	props: {
		verticalCard: { type: Boolean, default: true },
	},
	inject: ['manager'],
	computed: {
		productCardTags() {
			return this.$srv('productCardTags', [])
		},
		matchedAttrs() {
			return this.productCardTags
				.flatMap((tag) => {
					if (tag.collectionId) {
						let inCollection = this.manager.product.collectionsIds?.some((c) => c == tag.collectionId)
						if (!inCollection) return false
					}

					return this.manager.product.attrs.filter((attr) => {
						return attr.attrKey.k == tag.attrKeyId
					})
				})
				.filter(Boolean)
		},
		resultantAttrs() {
			if (!this.matchedAttrs?.length) return []

			let group = groupBy(this.matchedAttrs, (t) => t.attrKeyId)
			let keys = Object.keys(group)
			let resultantTags

			if (keys?.length) {
				resultantTags = keys.map((k) => {
					for (const g of group[k]) {
						return g
					}
				})
				if (resultantTags?.length < 3) {
					keys.forEach((key) => {
						let attribute = resultantTags.find((r) => r.attrKeyId == key)
						let result = group[key].find((g) => g.v != attribute.v)
						if (result) resultantTags.push(result)
					})
				}
				return resultantTags.slice(0, 4)
			}

			return resultantTags.slice(0, 4)
		},
	},
}
</script>

<template>
	<div
		v-if="resultantAttrs && resultantAttrs.length"
		class="product-card__attrs-tags d-flex align-center"
		:class="{
			'justify-center': manager.configData.alignment == 'center',
			'justify-end': manager.configData.alignment == 'right',
		}"
		style="gap: 6px"
	>
		<span
			v-for="({ v }, index) of resultantAttrs"
			:key="index"
			:class="['secondary font-0 font-weight-medium line-clamp-1 pa-1']"
		>
			{{ v }}
		</span>
	</div>
</template>

