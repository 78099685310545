var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.verticalCard)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.forMobileShop && _vm.onLinkBlurMobile),expression:"forMobileShop && onLinkBlurMobile"}],staticClass:"h100",class:_vm.smallWrapperClass},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('v-card',{class:[
				'd-flex flex-column rounded-md overflow-hidden base product-card',
				_vm.$bem('product-card-design4-small'),
				_vm.alignment == 'left' ? 'text-start' : _vm.alignment == 'center' ? 'text-center' : 'text-end',
				{ 'card-border': _vm.cardBorder } ],style:({ height: _vm.forMobileShop && _vm.hasHoverAction && _vm.hover ? 'calc(100% + 50px)' : '100%' }),attrs:{"hover":!_vm.outOfStock,"elevation":"2","ripple":false},on:{"click":_vm.cardClick}},[_c('ProductCard-FinancingTag'),_c('div',{class:[_vm.$bem('__image-cont'), { 'no-stock': _vm.outOfStock }, 'mx-2 mt-2']},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"cover":"","src":_vm.mainImage,"width":"100%","aspect-ratio":"1","imgClass":_vm.$b.mt
							? 'product-card-design4-small__image--smallWidth-mobile'
							: 'product-card-design4-small__image--smallWidth'}}),(_vm.secondImage && _vm.hover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), _vm.hover ? _vm.$bem('__second-image--show') : ''],attrs:{"cover":"","src":_vm.secondImage,"width":"100%","imgClass":_vm.$b.mt
								? 'product-card-design4-small__image--smallWidth-mobile'
								: 'product-card-design4-small__image--smallWidth'}})],1):_vm._e(),(_vm.hasLimitedTimeOffer)?_c('LimitedTimeOffer',{class:_vm.$bem('__offer'),attrs:{"product":_vm.product}}):_vm._e(),_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product,"imgWidth":"25px"}})],1),_c('div',{class:[
					_vm.$bem('__data-cont'),
					'd-flex flex-column mx-2',
					{ 'no-stock': _vm.outOfStock, 'h100 justify-space-between': _vm.hasVisibleAction } ],staticStyle:{"gap":"6px"}},[(_vm.brand)?_c('div',{class:[_vm.$bem('__brand'), 'font-0 line-clamp-1 px-1 mt-2']},[_vm._v(_vm._s(_vm.brand))]):_vm._e(),_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var navigate = ref.navigate;
				var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){_vm.forMobileShop ? _vm.onLinkClickMobile(navigate, $event) : navigate($event)}}},[_c('div',{class:[_vm.$bem('__name'), 'line-clamp-2 font-1 header px-1', { 'mt-2': !_vm.brand }]},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])])]}}],null,false,3253396994)}),_c('ProductCard-VariantSelector'),_c('ProductCard-TextTag'),_c('ProductCard-Tags',{staticClass:"font-0"}),_c('ProductCard-AttrsTags'),_c('ProductCardPrice',{class:_vm.$bem('__price-container'),attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":[
						_vm.$bem('__prev-price'),
						'd-flex align-baseline line-clamp-1',
						{ 'justify-center': _vm.alignment == 'center', 'justify-end': _vm.alignment == 'right' } ],"prevPriceClass":"grey--text font-0","priceClass":[
						_vm.$bem('__price'),
						'price font-3 line-clamp-1',
						{
							'text-center': _vm.alignment == 'center',
							'text-end': _vm.alignment == 'right',
						} ],"discountClass":[_vm.$bem('__discount'), 'd-inline-block discount font-0 ml-2'],"prevPriceGroup":""}}),_c('ProductCardRating',{attrs:{"product":_vm.product}}),_c('v-spacer'),_c('ProductCard-Actions')],1)],1)],1)],1):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.forMobileShop && _vm.onLinkBlurMobile),expression:"forMobileShop && onLinkBlurMobile"}],class:[
		'w100 product-card--horizontal',
		{
			'product-card-design4-small-horizontal-wrapper-forBtn': _vm.hasHoverAction,
			'product-card--align-right': _vm.alignment == 'right',
			'product-card--align-center': _vm.alignment == 'center',
		} ]},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('v-card',{class:[
				'd-flex overflow-hidden base product-card py-2',
				_vm.$bem('product-card-design4-small-horizontal'),
				_vm.alignment == 'left' ? 'text-start' : _vm.alignment == 'center' ? 'text-center' : 'text-end',
				{ 'product-card-design4-small-horizontal-forBtn': _vm.hasAction, 'card-border': _vm.cardBorder } ],attrs:{"hover":!_vm.outOfStock,"outlined":"","ripple":false},on:{"click":_vm.cardClick}},[_c('div',{class:[
					_vm.$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
					{ 'no-stock': _vm.outOfStock } ]},[_c('div',{staticClass:"h100 p-relative"},[_c('Media',{class:_vm.$bem('__main-image', 'px-1 h100'),attrs:{"src":_vm.mainImage,"width":"100%","imgClass":"product-card-design4-small-horizontal__image--smallWidth"}}),(_vm.secondImage && _vm.hover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont', 'h100')},[_c('Media',{class:[
								_vm.$bem('__second-image', 'h100'),
								_vm.hover ? _vm.$bem('__second-image--show') : '',
								'h100' ],attrs:{"src":_vm.secondImage,"width":"100%","imgClass":"product-card-design4-small-horizontal__image--smallWidth"}})],1):_vm._e()],1),_c('LimitedTimeOffer',{staticClass:"mx-2 rounded line-clamp-1 font-weight-regular",staticStyle:{"width":"inherit","padding":"0 4px !important"},attrs:{"product":_vm.product,"textAlign":"start"}})],1),_c('div',{staticClass:"col col-7 py-0 pl-1 d-flex flex-column justify-center",class:[_vm.$bem('__data-cont'), { 'no-stock': _vm.outOfStock }]},[_c('ProductCard-AttrsTags',{attrs:{"vertical-card":false}}),(_vm.brand)?_c('div',{class:[_vm.$bem('__brand'), 'font-0 line-clamp-1 mt-1']},[_vm._v(_vm._s(_vm.brand))]):_vm._e(),_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var navigate = ref.navigate;
							var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){_vm.forMobileShop ? _vm.onLinkClickMobile(navigate, $event) : navigate($event)}}},[_c('div',{class:[
								_vm.$bem('__name'),
								'line-clamp-2 font-2 header',
								{ 'mt-3': _vm.hasAction && !_vm.brand, 'mt-1': !_vm.brand } ]},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])])]}}])}),_c('ProductCard-VariantSelector'),_c('ProductCard-TextTag'),_c('ProductCard-Tags',{staticClass:"font-0"}),_c('ProductCardPrice',{class:_vm.$bem('__price-container'),attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":[_vm.$bem('__prev-price'), 'd-flex align-center pb-2 line-clamp-1'],"prevPriceClass":"grey--text font-0","priceClass":[_vm.$bem('__price'), 'price font-4 line-clamp-1'],"discountClass":[_vm.$bem('__discount'), 'd-inline-block discount font-0'],"prevPriceGroup":"","horizontalCard":""}}),_c('ProductCardRating',{attrs:{"product":_vm.product}}),_c('ProductCard-Actions'),_c('ProductCard-FinancingTag'),_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product,"imgWidth":"30px"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }