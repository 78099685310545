<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	mixins: [productCardMixin],
	lang: 'shop',
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		v-if="verticalCard"
		:class="[
			verticalWrapperClasses,
			{
				'product-card--align-right': alignment == 'right',
				'product-card--align-center': alignment == 'center',
			},
		]"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock"
				@click="cardClick"
				:ripple="false"
				elevation="2"
				:class="[
					$bem('product-card-design1-vertical'),
					alignment == 'left' ? 'text-start' : alignment == 'center' ? 'text-center' : 'text-end',
					{ 'card-border': cardBorder },
					verticalCardClasses,
				]"
				:style="{ height: hasHoverAction && hover ? hoverForBtn : '100%' }"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }]">
					<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
					<div v-if="secondImage && hover && imgHover" :class="$bem('__second-image-cont')">
						<Media
							:src="secondImage"
							width="100%"
							:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
						/>
					</div>

					<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-3',
						{ 'no-stock': outOfStock, h100: hasVisibleAction },
					]"
					style="gap: 6px"
				>
					<router-link class="card-anchor header" :to="productRoute">
						<div :class="[$bem('__name'), 'line-clamp-2 font-2 px-1 header mt-2']">
							{{ product.name }}
						</div>
					</router-link>

					<ProductCard-VariantSelector />

					<ProductCard-FinancingTag />

					<ProductCard-TextTag />

					<ProductCard-Tags class="font-0" />

					<ProductCard-AttrsTags />

					<ProductCardPrice
						:class="$bem('__price-container')"
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							$bem('__prev-price'),
							'd-flex align-center line-clamp-1 px-1',
							{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
						]"
						prevPriceClass="grey--text font-2"
						:priceClass="[
							$bem('__price'),
							'price font-6 line-clamp-1 mt-2 px-1',
							{
								'text-center': alignment == 'center',
								'text-end': alignment == 'right',
							},
						]"
						:discountClass="[$bem('__discount'), 'd-inline-block discount font-2']"
						prevPriceGroup
					/>

					<ProductCardRating :product="product" />

					<v-spacer />

					<ProductCard-Actions />

					<ProductWishlistToggler
						:productId="product.id"
						:class="$bem('__wishlist-toggler')"
						style="z-index: 1"
					/>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE SHOP -->
	<div class="w100 product-card--horizontal" v-else v-click-outside="onLinkBlurMobile">
		<v-card
			@click="cardClick"
			:ripple="false"
			:class="[
				$bem('product-card-design1-horizontal'),
				{ 'card-border': cardBorder },
				horizontalCardClasses,
			]"
		>
			<div
				:class="[
					$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
					{ 'no-stock': outOfStock },
				]"
			>
				<Media
					:class="$bem('__main-image', 'px-1')"
					:src="mainImage"
					width="100%"
					imgClass="product-card-design1-horizontal__image--smallWidth"
				/>

				<LimitedTimeOffer
					:product="product"
					textAlign="start"
					class="mx-2 rounded line-clamp-1 font-weight-regular"
					style="width: inherit; padding: 0 4px !important"
					v-if="hasLimitedTimeOffer"
				/>

				<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
			</div>

			<div
				class="col col-7 py-0 pl-1 d-flex flex-column text-start"
				:class="[$bem('__data-cont'), { 'no-stock': outOfStock }]"
				style="gap: 6px"
			>
				<router-link :to="productRoute" custom v-slot="{ navigate, href }">
					<a class="card-anchor header" @click="onLinkClickMobile(navigate, $event)" :href="href">
						<div :class="[$bem('__name'), 'line-clamp-2 font-3 header']">{{ product.name }}</div>
					</a>
				</router-link>

				<ProductCard-VariantSelector />

				<ProductCard-FinancingTag />

				<ProductCard-TextTag />

				<ProductCard-Tags class="font-0" />

				<ProductCard-AttrsTags :vertical-card="false" />

				<ProductCardPrice
					:class="[
						$bem('__price-container'),
						'd-flex',
						{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
					]"
					:product="product"
					:currency="currency"
					:priceAmount="price"
					:prevPriceAmount="prevPrice"
					:discountPct="discountPct"
					:containerClass="[$bem('__prev-price'), 'd-flex align-center pb-2 line-clamp-1']"
					prevPriceClass="grey--text font-1"
					:priceClass="[$bem('__price'), 'price font-6 line-clamp-1']"
					:discountClass="[$bem('__discount'), 'd-inline-block discount font-1']"
					prevPriceGroup
					horizontalCard
				/>

				<ProductWishlistToggler
					:productId="product.id"
					:class="[$bem('__wishlist-toggler'), { 'no-stock': outOfStock }]"
					style="z-index: 1"
					:height="30"
					:width="30"
				/>

				<ProductCardRating :product="product" />

				<v-spacer />

				<ProductCard-Actions />
			</div>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE SHOP -->
</template>

<style lang="scss" scoped>
.card-border {
	border: 1px solid #b2b2b2;
}

.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design1-vertical-wrapper {
	min-height: 380px;
	width: 250px;

	&-forBtn {
		padding-bottom: 30px;
	}

	&--for-btn-with-tags {
		padding-bottom: 56px;
	}
}

.product-card-design1-vertical {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		bottom: -4px;
	}

	&__event-icon {
		position: absolute;
		bottom: -15px;
		left: 8px;
		z-index: 1;
		width: 40px;
	}

	&__name {
		display: -webkit-box;
		margin-top: 15px;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}
}

::v-deep .product-card-design1-vertical {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.alignment-right {
		float: right;
	}

	&__price {
		display: -webkit-box;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 3px;
		border-radius: 4px;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design1-horizontal {
	// min-height: 200px;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__event-icon {
		position: absolute;
		top: 2px;
		right: 4px;
		z-index: 1;
		width: 25px;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 7px;
		left: 6px;
		z-index: 1;
	}

	&__name {
		display: -webkit-box;
		// min-height: 53.19px;
	}
}

::v-deep div.product-card-design1-horizontal {
	&__prev-price {
		display: -webkit-box;
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}

	&__image--smallWidth {
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}
}
</style>

