<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		v-if="verticalCard"
		:class="['h100', smallWrapperClass]"
		v-click-outside="forMobileShop && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock"
				elevation="2"
				@click="cardClick"
				:ripple="false"
				:class="[
					'd-flex flex-column rounded-md overflow-hidden base product-card',
					$bem('product-card-design3-small'),
					alignment == 'left' ? 'text-start' : alignment == 'center' ? 'text-center' : 'text-end',
					{ 'card-border': cardBorder },
				]"
				:style="{ height: forMobileShop && hasHoverAction && hover ? 'calc(100% + 50px)' : '100%' }"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }, 'mx-2 mt-2']">
					<Media
						cover
						:class="$bem('__main-image')"
						:src="mainImage"
						width="100%"
						aspect-ratio="1"
						:imgClass="
							$b.mt
								? 'product-card-design3-small__image--smallWidth-mobile'
								: 'product-card-design3-small__image--smallWidth'
						"
					/>
					<div v-if="secondImage && hover && imgHover" :class="$bem('__second-image-cont')">
						<Media
							cover
							:src="secondImage"
							width="100%"
							:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							:imgClass="
								$b.mt
									? 'product-card-design3-small__image--smallWidth-mobile'
									: 'product-card-design3-small__image--smallWidth'
							"
						/>
					</div>

					<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-2',
						{ 'no-stock': outOfStock, h100: hasVisibleAction },
					]"
					style="gap: 6px"
				>
					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="forMobileShop ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<div :class="[$bem('__name'), 'line-clamp-2 font-1 header px-1 mt-2']">
								{{ product.name }}
							</div>
						</a>
					</router-link>

					<ProductCard-VariantSelector />

					<ProductCard-TextTag />

					<ProductCard-Tags class="font-0" />

					<ProductCardPrice
						:class="$bem('__price-container')"
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							'd-flex align-center',
							{
								'justify-center': alignment == 'center',
								'justify-end w100': alignment == 'right',
								'pb-1': !hasAction,
							},
						]"
						:prevPriceClass="[$bem('__prev-price'), 'grey--text font-0 ml-1']"
						:priceClass="[$bem('__price'), 'price font-3 line-clamp-1 pt-1']"
						:discountClass="[
							$bem('__discount'),
							'd-inline-block discount font-1',
							{
								'alignment-center': alignment == 'center',
								'alignment-right': alignment == 'right',
							},
						]"
					/>

					<ProductCard-FinancingTag />

					<ProductCardRating :product="product" />

					<v-spacer />

					<ProductCard-Actions />
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD -->
	<div
		v-else
		:class="[
			'w100 product-card--horizontal',
			{
				'product-card-design3-small-horizontal-wrapper-forBtn': hasHoverAction,
				'product-card--align-right': alignment == 'right',
				'product-card--align-center': alignment == 'center',
			},
		]"
		v-click-outside="forMobileShop && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock"
				outlined
				@click="cardClick"
				:ripple="false"
				:class="[
					'd-flex overflow-hidden base product-card py-2',
					$bem('product-card-design3-small-horizontal'),
					{ 'product-card-design3-small-horizontal-forBtn': hasAction, 'card-border': cardBorder },
				]"
			>
				<div
					:class="[
						$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
						{ 'no-stock': outOfStock },
					]"
				>
					<div class="h100 p-relative">
						<Media
							:class="$bem('__main-image', 'px-1 h100')"
							:src="mainImage"
							width="100%"
							imgClass="product-card-design3-small-horizontal__image--smallWidth"
						/>
						<div
							v-if="secondImage && hover && imgHover"
							:class="$bem('__second-image-cont', 'px-1 h100')"
						>
							<Media
								:src="secondImage"
								width="100%"
								:class="[
									$bem('__second-image', 'h100'),
									hover ? $bem('__second-image--show') : '',
									'h100',
								]"
								imgClass="product-card-design3-small-horizontal__image--smallWidth"
							/>
						</div>
					</div>
					<LimitedTimeOffer
						:product="product"
						textAlign="start"
						class="mx-2 rounded line-clamp-1 font-weight-regular"
						style="width: inherit; padding: 0 4px !important"
						:class="$bem('__offer')"
					/>
				</div>

				<div
					class="col col-7 py-0 pl-1 d-flex flex-column"
					:class="[$bem('__data-cont'), { 'no-stock': outOfStock }]"
					style="gap: 6px"
				>
					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="forMobileShop ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<div :class="[$bem('__name'), 'line-clamp-2 font-2 header mt-1', { 'mt-3': hasAction }]">
								{{ product.name }}
							</div>
						</a>
					</router-link>

					<ProductCard-VariantSelector />

					<ProductCard-TextTag />

					<ProductCard-Tags class="font-0" />

					<ProductCardPrice
						:class="$bem('__price-container')"
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="'d-flex align-center line-clamp-1 pb-1'"
						:prevPriceClass="['grey--text font-0', $bem('__prev-price')]"
						:priceClass="[$bem('__price'), 'price font-4 line-clamp-1 mr-1']"
						:discountClass="[$bem('__discount'), 'd-inline-block discount font-1']"
						horizontalCard
					/>

					<ProductCard-FinancingTag />

					<ProductCardRating :product="product" />

					<v-spacer />

					<ProductCard-Actions />

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="30px" />
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF HORIZONTAL CARD -->
</template>

<style lang="scss" scoped>
.card-border {
	border: 1px solid #b2b2b2;
}

.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design3-small-wrapper {
	// min-height: 270px;
	width: 100%;
	min-width: 100px;

	&-forBtn {
		padding-bottom: 50px;
		width: 100%;
	}
}

.product-card-design3-small-wrapper-mobile {
	width: 90%;

	&-forBtn {
		width: 90%;
	}
}

.product-card-design3-small {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		top: 0;
	}

	&__event-icon {
		position: absolute;
		bottom: 0;
		right: 5px;
		z-index: 1;
		width: 25px;
	}

	&__name {
		display: -webkit-box;
	}

	&__price-container {
		display: flex;
		align-items: flex-end;
	}
}

::v-deep .product-card-design3-small {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.alignment-right {
		float: right;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__image--smallWidth {
		min-width: 180px;
		min-height: 180px;
		width: 100%;
		height: 100%;
		left: 50% !important;
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}

	&__image--smallWidth-mobile {
		max-width: 170px;
		max-height: 170px;
		width: 100%;
		left: 50% !important;
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}

	& .product-card__financing-wrapper {
		min-height: 23.19px;
	}

	& .product-card__rating-wrapper {
		min-height: 31.8px;
	}

	& .product-card__attrs-tags {
		min-height: 38px;
		padding: 0 0 8px 0;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design3-small-horizontal {
	// height: 200px;

	// &-forBtn {
	// 	height: 240px;
	// }

	height: 100%;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__second-image-cont {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		top: 5px;
		left: 5px;
		margin-right: 10px;
	}

	&__event-icon {
		position: absolute;
		top: 4px;
		right: 10px;
		z-index: 1;
		width: 25px;
	}

	&__name {
		display: -webkit-box;
	}

	&__price-container {
		display: flex;
		align-items: flex-end;
	}
}

::v-deep div.product-card-design3-small-horizontal {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.alignment-right {
		float: right;
	}

	&__image--smallWidth {
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}

	&__prev-price {
		display: -webkit-box;
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}
}
</style>

